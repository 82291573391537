import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ReactMarkdown from 'react-markdown'

const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <div className="privacy">
      <h2 className="privacy__header">{data.privacy.edges[0].node.title}</h2>
      <h5 className="privacy__subheader">
        {data.privacy.edges[0].node.subtitle}
      </h5>
      <div className="container">
        <div className="row">
          <div className="privacy__description col-12 col-md-8 offset-md-2">
            <ReactMarkdown
              source={data.privacy.edges[0].node.description.description}
              escapeHtml={false}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    privacy: allContentfulPrivacy {
      edges {
        node {
          title
          subtitle
          description {
            description
          }
        }
      }
    }
  }
`
export default PrivacyPage
